.shop- {
  &header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.85)),
      url("../../../img/bg/welcome2.jpg");
    height: 40%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  &name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &content {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -8%);
    color: white;
  }
}
.dot {
  position: relative;
  margin-top: 2px;
  height: 15px;
  width: 15px;
  border: 1px solid #008000;
  display: block;
  &:before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 32%;
    left: 27%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #008000;
  }
  &.nonveg {
    border: 1px solid #b90d2e;
    &:before {
      background: #b90d2e;
    }
  }
}
.nav- {
  &address {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.item- {
  &name {
    font-size: 14px;
    line-height: 16px;
  }
  &description {
    font-size: 10px;
    line-height: 12px;
  }
}
.pack- {
  &box {
    height: 32px;
    width: 80px;
    border: 1px solid #d4d5d9;
    background-color: #fff;
    contain: content;
    overflow: hidden;
    position: relative;
    border-radius: 4px;
  }
  &count {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
    line-height: 30px;
    color: #60b246;
    font-weight: 600;
  }
  &btn {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
    margin-top: 2px;
    height: 100%;
    background: 0 0;
    background: none;
    border: none;
    outline: 0;
    text-align: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:hover,
    :active,
    :checked,
    :enabled,
    :focus,
    :active:focus:not(:disabled):not(.disabled),
    :not(:disabled):not(.disabled):active {
      color: transparent !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }
    &:before {
      content: "";
      display: block;
      height: 2px;
      width: 10px;
      background-color: #bebfc5;
      position: relative;
      top: 0;
      left: 40%;
    }
    &-plus {
      display: inline-block;
      width: 22px;
      height: 100%;
      outline: none;
      border: 0;
      background: linear-gradient(#bebfc5, #bebfc5),
        linear-gradient(#bebfc5, #bebfc5);
      background-position: center;
      background-size: 65% 2.5px, 2.5px 40%;
      background-repeat: no-repeat;

      &:hover,
      :active,
      :checked,
      :enabled,
      :focus,
      :active:focus:not(:disabled):not(.disabled),
      :not(:disabled):not(.disabled):active {
        color: transparent !important;
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }
  }
}
